import * as React from "react";
import { Stack } from "@mui/material";

export interface InnerContainerProps {
  children: React.ReactNode;
}

function InnerContainer({ children }: InnerContainerProps): JSX.Element {
  return (
    <Stack bgcolor="white" p={5} gap={5}>
      {children}
    </Stack>
  );
}

export default InnerContainer;
