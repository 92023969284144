import { makeStyles } from "@mui/styles";
import ResizeButton from "../assets/resize-bottom-right.svg";

export const getConfigDialogStyles = makeStyles({
  resizable: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "center",
    position: "relative",
    minHeight: "5px",
    overflow: "hidden",
    "& .react-resizable-handle": {
      position: "absolute",
      width: 24,
      height: 24,
      bottom: 0,
      right: 0,
      background: `url(${ResizeButton})`,
      "background-position": "bottom right",
      padding: "0 3px 3px 0",
      "background-repeat": "no-repeat",
      "background-origin": "content-box",
      "box-sizing": "border-box",
      cursor: "se-resize",
    },
  },
});
