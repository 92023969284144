// Install and load fonts
import "@fontsource/ibm-plex-sans/300.css";
import "@fontsource/ibm-plex-sans/400.css";
import "@fontsource/ibm-plex-sans/500.css";
import "@fontsource/ibm-plex-sans/700.css";
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import CssBaseline from "@mui/material/CssBaseline";
import { AuthProvider } from "react-oidc-context";
import { oidcConfig } from "./shared/auth/oidc";
import App from "./features/App";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

function onSigninCallback() {
  // removing the state url parameter from the URL on successful login
  window.location.href = "/";
}

root.render(
  <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
    {/* CSS reset for cross-browser design consistency */}
    <CssBaseline />
    <App />
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
