import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  CancelButton,
  PrimaryButton,
} from "@web-applications/allinga-web-components";

export interface ConfigurationTemplateActionsProps {
  onSave: VoidFunction;
  onCancel: VoidFunction;
}

function ConfigurationTemplateActions({
  onSave,
  onCancel,
}: ConfigurationTemplateActionsProps): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <CancelButton
        id="profile-settings-cancel"
        handleOnClick={onCancel}
        text={t("buttons.cancel")}
      />
      <PrimaryButton
        id="profile-settings-save"
        onSubmit={onSave}
        text={t("buttons.save")}
      />
    </>
  );
}

export default ConfigurationTemplateActions;
