import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { PrimaryButton } from "@web-applications/allinga-web-components";
import { useOIDCLogin } from "../../shared/hooks/useOIDCLogin";
import { SPEAKER_PROJECT_URL } from "../../shared/constants/urls";
import Footer from "../../shared/containers/Footer";
import "../../App.css";

function App() {
  const { t } = useTranslation();
  const { auth } = useOIDCLogin();

  const handleLogin = () => {
    auth.signinRedirect();
  };

  return (
    <Stack
      sx={{
        height: "100%!important",
        maxWidth: "100%!important",
      }}
    >
      <Stack
        className="background-image container-wrapper"
        direction="column"
        justifyContent="center"
        sx={{
          height: "calc(100vh - 168px)",
        }}
      >
        <Stack
          direction="row"
          spacing={4}
          sx={{
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Stack>
            <Typography variant="h1landing">{t("homePage.text1")}</Typography>
            <Typography variant="h3" fontWeight={400}>
              {t("homePage.text2")}
            </Typography>
            <Stack my={5} direction="row" gap={2}>
              <PrimaryButton
                text={t("buttons.logIn")}
                onSubmit={handleLogin}
                id="login-button"
              />
              <Button
                variant="outlined"
                endIcon={<ArrowForwardIcon />}
                onClick={() => {
                  window.open(SPEAKER_PROJECT_URL);
                }}
                id="learn-more-button"
              >
                {t("buttons.learnMore")}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Footer />
    </Stack>
  );
}

export default App;
