import { Box, Stack, Typography } from "@mui/material";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import "../../App.css";
import { userPreferencesAtom } from "../../atoms/user-preferences";
import PlatformApplications from "./PlatformApplications";
import Overview from "./Overview";

function App() {
  const [userPreferences] = useAtom(userPreferencesAtom);
  const { t } = useTranslation();
  return (
    <Stack className="content-container" direction="column" gap={5} my={10}>
      <Box
        className="flex-container"
        sx={{
          alignItems: "center",
          marginBottom: 3,
        }}
      >
        {userPreferences.avatar && (
          <Box
            component="img"
            src={userPreferences.avatar}
            alt="User Avatar"
            sx={{
              width: 96,
              height: 96,
              marginRight: "40px",
            }}
          />
        )}
        <Box>
          <Typography variant="h1" id="dashboard-welcome-text">
            {t("dashboard.welcome")}, {userPreferences.username || "User"}!
          </Typography>
        </Box>
      </Box>
      <Overview />
      <PlatformApplications />
    </Stack>
  );
}

export default App;
