/**
 * Contains the paths used for routing
 */

export const PATHS = {
  root: "/",
  groups: "groups",
  users: "users",
  privacy: "privacy",
  imprint: "imprint",
};
