import axios from "axios";
import { USER_PREFERENCES_BASE_URL } from "../constants/backend";
import { generateAuthorizationHeaders } from "../utils/request";
import { UserPreferences } from "../models/user-preferences";

export async function fetchUserPreferences(
  token = ""
): Promise<UserPreferences> {
  const headers = generateAuthorizationHeaders(token);

  const response = await axios.get<UserPreferences>(
    `${USER_PREFERENCES_BASE_URL}/`,
    {
      headers,
    }
  );
  return response.data;
}

export async function updateUserPreferences(
  userAvatar: string,
  userLanguage: string,
  username: string,
  token = ""
): Promise<string> {
  const headers = generateAuthorizationHeaders(token);

  const response = await axios.patch<string>(
    `${USER_PREFERENCES_BASE_URL}/`,
    { avatar: userAvatar, language: userLanguage, username },
    {
      headers,
    }
  );

  return response.data;
}
