import { Dialog } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import { ResizableBox } from "react-resizable";
import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";
import { useAtom } from "jotai";
import { getConfigDialogStyles } from "../../../styles/config-dialog-styles";
import { isConfigDialogOpenAtom } from "../../../atoms";
import { useWindowConstraints } from "../../hooks/useWindowConstraints";

interface DialogResizableBoxProps {
  children: ReactNode;
}

function PaperComponent(props: PaperProps) {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      nodeRef={nodeRef}
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function DialogResizableBox({
  children,
}: DialogResizableBoxProps): ReactElement {
  const maxConstraints = useWindowConstraints();
  const classes = getConfigDialogStyles();
  const [isConfigDialogOpen, setConfigDialogOpen] = useAtom(
    isConfigDialogOpenAtom
  );

  const handleClose = () => {
    setConfigDialogOpen(false);
  };

  return (
    <Dialog
      open={isConfigDialogOpen}
      onClose={handleClose}
      maxWidth={false}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      data-testid="configuration-dialog"
    >
      <ResizableBox
        minConstraints={[550, 550]}
        maxConstraints={maxConstraints}
        width={792}
        height={720}
        className={classes.resizable}
      >
        {children}
      </ResizableBox>
    </Dialog>
  );
}

export default DialogResizableBox;
