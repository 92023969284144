import { atom } from "jotai";

/**
 * Primitives
 */

/**
 * Atom to store the authentication token retrieved from the Keycloak.
 * This token should be sent whenever a request is made to fetch data from the backend
 */
export const authTokenAtom = atom<string>("");

authTokenAtom.debugLabel = "AUTH_TOKEN";
