import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import InnerContainer from "shared/components/InnerContainer";
import theme from "styles/theme";
import { ReactComponent as SpeakerSDKIcon } from "assets/SpeakerSDKIcon.svg";
import { ReactComponent as SpeakerStageIcon } from "assets/SpeakerStageIcon.svg";

const Item = styled(Paper)({
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    cursor: "pointer",
  },
});

function PlatformApplications() {
  const { t } = useTranslation();

  const gridItems = [
    {
      href: "https://stage.allinga-plattform.de",
      icon: <SpeakerStageIcon />,
      title: "platformApplications.allingaStageTitle",
      text: "platformApplications.allingaStageText",
    },
    {
      href: "https://gitlab.cc-asp.fraunhofer.de/fraunhofer-speech-assistant/platform/speaker-sdk",
      icon: <SpeakerSDKIcon />,
      title: "platformApplications.SDKTitle",
      text: "platformApplications.SDKText",
    },
  ];

  return (
    <Stack id="applications-container">
      <InnerContainer>
        <Typography variant="h3" id="applications-header">
          {t("platformApplications.header")}
        </Typography>
        <Grid container spacing={5} columns={4}>
          {gridItems.map(({ href, icon, title, text }) => (
            <Grid
              item
              xs={4}
              sm={2}
              component={Link}
              href={href}
              sx={{ textDecoration: "none" }}
              key={title}
              id={title}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Item>
                <Box className="flex-container">
                  <Box>{icon}</Box>
                  <Box>
                    <Typography variant="h5" color="text.primary" my={1}>
                      {t(title)}
                    </Typography>
                    <Typography variant="body1">{t(text)}</Typography>
                  </Box>
                </Box>
              </Item>
            </Grid>
          ))}
        </Grid>
      </InnerContainer>
    </Stack>
  );
}

export default PlatformApplications;
