import { Stack, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";

/**
 * React component to display the legal summary
 */
function Privacy() {
  const { t } = useTranslation();

  return (
    <Stack
      className="container-wrapper outer-container background-color"
      direction="row"
    >
      <Box className="inner-container">
        <Stack direction="column" spacing={3}>
          <Stack spacing={1}>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              {t("legalTerms.privacy.heading")}
            </Typography>
            <Typography>{t("legalTerms.privacy.introP1")}</Typography>
            <Typography>{t("legalTerms.privacy.introP2")}</Typography>
          </Stack>

          <Stack spacing={1}>
            <Link href="privacy#section1">
              {t("legalTerms.privacy.section1.heading")}
            </Link>
            <Link href="privacy#section2">
              {t("legalTerms.privacy.section2.heading")}
            </Link>
            <Link href="privacy#section3">
              {t("legalTerms.privacy.section3.heading")}
            </Link>
            <Link href="privacy#section4">
              {t("legalTerms.privacy.section4.heading")}
            </Link>
            <Link href="privacy#section5">
              {t("legalTerms.privacy.section5.heading")}
            </Link>
            <Link href="privacy#section6">
              {t("legalTerms.privacy.section6.heading")}
            </Link>
            <Link href="privacy#section7">
              {t("legalTerms.privacy.section7.heading")}
            </Link>
            <Link href="privacy#section8">
              {t("legalTerms.privacy.section8.heading")}
            </Link>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5" id="section1">
              {t("legalTerms.privacy.section1.heading")}
            </Typography>
            <Typography>{t("legalTerms.privacy.section1.text")}</Typography>
            <Typography>
              <ul>
                <li>{t("legalTerms.privacy.section1.speakerPlattform")}</li>
                <li>{t("legalTerms.privacy.section1.allingaPlattform")}</li>
                <li>{t("legalTerms.privacy.section1.allingaPlattform2")}</li>
              </ul>
            </Typography>
            <Typography>{t("legalTerms.privacy.section1.text2")}</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5" id="section2">
              {t("legalTerms.privacy.section2.heading")}
            </Typography>
            <Typography>
              {t("legalTerms.privacy.section2.text1")}
              <br />
              Fraunhofer-Gesellschaft zur Förderung der angewandten Forschung
              e.V.
              <br />
              Hansastraße 27 c
              <br />
              80686 {t("legalTerms.privacy.section2.munich")}
            </Typography>
            <Typography>{t("legalTerms.privacy.section2.onBehalf")}</Typography>
            <Typography>
              Institut für Intelligente Analyse- und Informationssysteme IAIS
              <br />
              Schloss Birlinghoven 1
              <br />
              53757 Sankt Augustin
              <br />
              <Typography my={0.5}>
                {t("legalTerms.privacy.section2.andIts")}
              </Typography>
              Institut für Integrierte Schaltungen IIS
              <br />
              Am Wolfsmantel 33
              <br />
              91058 Erlangen
              <br />
              {t("legalTerms.privacy.section2.referredTo")}
            </Typography>
            <Typography>
              {t("legalTerms.privacy.section2.email")}
              <Link href="mailto:allinga@iais.fraunhofer.de">
                allinga@iais.fraunhofer.de
              </Link>
              <br />
              {t("legalTerms.privacy.section2.phone")} : +49 9131 776-0
              <br />
              Fax : +49 9131 776-2019
            </Typography>
            <Typography>{t("legalTerms.privacy.section2.text2")}</Typography>
            <Typography>
              {t("legalTerms.privacy.section2.text3")}{" "}
              <Link href="mailto:allinga@iais.fraunhofer.de">
                allinga@iais.fraunhofer.de
              </Link>
            </Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5" id="section3">
              {t("legalTerms.privacy.section3.heading")}
            </Typography>
            <Typography variant="h6">
              {t("legalTerms.privacy.section3.subHeading1")}
            </Typography>
            <Typography>{t("legalTerms.privacy.section3.text1.1")}</Typography>
            <Typography>{t("legalTerms.privacy.section3.text1.2")}</Typography>
            <Typography>{t("legalTerms.privacy.section3.text1.3")}</Typography>
            <Typography>{t("legalTerms.privacy.section3.text1.4")}</Typography>
            <Typography>{t("legalTerms.privacy.section3.text1.5")}</Typography>

            <Typography variant="h6">
              {t("legalTerms.privacy.section3.subHeading2")}
            </Typography>
            <Typography>{t("legalTerms.privacy.section3.text2.1")}</Typography>
            <Typography>{t("legalTerms.privacy.section3.text2.2")}</Typography>
            <Typography>
              <ul>
                <li>{t("legalTerms.privacy.section3.names")}</li>
                <li>{t("legalTerms.privacy.section3.email")}</li>
              </ul>
            </Typography>

            <Typography>{t("legalTerms.privacy.section3.text2.3")}</Typography>
            <Typography>{t("legalTerms.privacy.section3.text2.4")}</Typography>
            <Typography>{t("legalTerms.privacy.section3.text2.5")}</Typography>
            <Typography>{t("legalTerms.privacy.section3.text2.6")}</Typography>
            <ul>
              <li>{t("legalTerms.privacy.section3.listItem2.6.1")}</li>
              <li>{t("legalTerms.privacy.section3.listItem2.6.2")}</li>
              <li>{t("legalTerms.privacy.section3.listItem2.6.3")}</li>
              <li>{t("legalTerms.privacy.section3.listItem2.6.4")}</li>
              <li>{t("legalTerms.privacy.section3.listItem2.6.5")}</li>
              <li>{t("legalTerms.privacy.section3.listItem2.6.6")}</li>
              <li>{t("legalTerms.privacy.section3.listItem2.6.7")}</li>
              <li>{t("legalTerms.privacy.section3.listItem2.6.8")}</li>
              <li>{t("legalTerms.privacy.section3.listItem2.6.9")}</li>
              <li>{t("legalTerms.privacy.section3.listItem2.6.10")}</li>
              <li>{t("legalTerms.privacy.section3.listItem2.6.11")}</li>
            </ul>
            <Typography>{t("legalTerms.privacy.section3.text2.7")}</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5" id="section4">
              {t("legalTerms.privacy.section4.heading")}
            </Typography>
            <Typography>{t("legalTerms.privacy.section4.text1")}</Typography>
            <Typography>
              {t("legalTerms.privacy.section4.listheading")}
            </Typography>
            <Typography>
              <ul>
                <li>{t("legalTerms.privacy.section4.listItem1")}</li>
                <li>{t("legalTerms.privacy.section4.listItem2")}</li>
                <li>{t("legalTerms.privacy.section4.listItem3")}</li>
              </ul>
            </Typography>

            <Typography>{t("legalTerms.privacy.section4.text2")}</Typography>
            <Typography>{t("legalTerms.privacy.section4.text3")}</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5" id="section5">
              {t("legalTerms.privacy.section5.heading")}
            </Typography>
            <Typography>{t("legalTerms.privacy.section5.text1")}</Typography>
            <Typography>{t("legalTerms.privacy.section5.text2")}</Typography>
            <Typography>{t("legalTerms.privacy.section5.text3")}</Typography>
            <Typography>{t("legalTerms.privacy.section5.text4")}</Typography>
            <Typography>{t("legalTerms.privacy.section5.text5")}</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5" id="section6">
              {t("legalTerms.privacy.section6.heading")}
            </Typography>
            <Typography>{t("legalTerms.privacy.section6.text1")}</Typography>
            <Typography>
              <ul>
                <li>{t("legalTerms.privacy.section6.listItem1")}</li>
                <li>{t("legalTerms.privacy.section6.listItem2")}</li>
                <li>{t("legalTerms.privacy.section6.listItem3")}</li>
                <li>{t("legalTerms.privacy.section6.listItem4")}</li>
                <li>{t("legalTerms.privacy.section6.listItem5")}</li>
                <li>{t("legalTerms.privacy.section6.listItem6")}</li>
                <li>{t("legalTerms.privacy.section6.listItem7")}</li>
              </ul>
            </Typography>
            <Typography variant="h6">
              {t("legalTerms.privacy.section6.subHeading")}
            </Typography>
            <Typography>{t("legalTerms.privacy.section6.text2")}</Typography>
            <Typography>{t("legalTerms.privacy.section6.text3")}</Typography>
            <Typography>{t("legalTerms.privacy.section6.text4")}</Typography>
            <Typography>{t("legalTerms.privacy.section6.text5")}</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5" id="section7">
              {t("legalTerms.privacy.section7.heading")}
            </Typography>
            <Typography>{t("legalTerms.privacy.section7.text1")}</Typography>
            <Typography>{t("legalTerms.privacy.section7.text2")}</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5" id="section8">
              {t("legalTerms.privacy.section8.heading")}
            </Typography>
            <Typography>{t("legalTerms.privacy.section8.text")}</Typography>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
}

export default Privacy;
