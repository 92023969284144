import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import EmptyStateGroup from "shared/components/EmptyStateGroup";
import InnerContainer from "shared/components/InnerContainer";

function Groups() {
  const { t } = useTranslation();
  return (
    <Stack className="content-container" my={10}>
      <InnerContainer>
        <Typography variant="h3">{t("groups.header")}</Typography>
        <EmptyStateGroup />
      </InnerContainer>
    </Stack>
  );
}

export default Groups;
