import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import EmptyStateGroup from "shared/components/EmptyStateGroup";
import InnerContainer from "shared/components/InnerContainer";

function Overview() {
  const { t } = useTranslation();
  return (
    <InnerContainer>
      <Typography variant="h3" id="overview-header">
        {t("overview.header")}
      </Typography>
      <EmptyStateGroup />
    </InnerContainer>
  );
}
export default Overview;
