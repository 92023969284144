import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import { PrimaryButton } from "@web-applications/allinga-web-components";
import { ReactComponent as EmptyClipboard } from "../../assets/EmptyClipboard.svg";

function EmptyStateGroup() {
  const { t } = useTranslation();
  return (
    <Stack direction="column" alignItems="center" gap={5}>
      <EmptyClipboard />
      <Typography variant="body1">{t("groups.emptyState")}</Typography>
      <PrimaryButton>{t("groups.createButton")}</PrimaryButton>
    </Stack>
  );
}

export default EmptyStateGroup;
