import React, { ReactElement } from "react";
import { LanguageSelector } from "@web-applications/allinga-web-components";
import { useTranslation } from "react-i18next";

interface LanguageSelectorSettingsProps {
  selectedLanguage: string;
  setSelectedLanguage: React.Dispatch<React.SetStateAction<string>>;
}

function LanguageSelectorSettings({
  selectedLanguage,
  setSelectedLanguage,
}: LanguageSelectorSettingsProps): ReactElement {
  const { t } = useTranslation();

  const languages = {
    en: {
      value: "en",
      renderValue: t("language.en"),
      displayValue: t("language.en"),
    },
    de: {
      value: "de",
      renderValue: t("language.de"),
      displayValue: t("language.de"),
    },
  };

  return (
    <LanguageSelector
      systemLanguageDescription={t("language.description")}
      systemLanguageHeader={t("language.heading")}
      languageLabel={t("language.label")}
      languages={languages}
      selectedLanguage={selectedLanguage}
      setLanguage={setSelectedLanguage}
    />
  );
}

export default LanguageSelectorSettings;
