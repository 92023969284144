import { createTheme, responsiveFontSizes } from "@mui/material/styles";

/**
 * Create Material UI theme for Allinga Admin
 */
const theme = responsiveFontSizes(
  createTheme({
    shape: {
      borderRadius: 0,
    },
    palette: {
      text: {
        primary: "rgba(0, 0, 0, 0.87)",
        secondary: "rgba(0, 0, 0, 0.6)",
        disabled: "rgba(0, 0, 0, 0.3)",
      },
      background: {
        default: "rgba(245, 245, 245, 0)" /* #f5f5f5 */,
      },
      primary: {
        main: "rgba(0, 134, 103, 1)" /* #008667 */,
        dark: "rgba(0, 102, 75, 1)" /* #00664B */,
        light: "rgba(127, 201, 182, 1)" /* #7FC9B6 */,
        contrastText: "rgba(255, 255, 255, 1)" /* #fff */,
      },
      secondary: {
        main: "rgba(51, 184, 202, 1)" /* #33B8CA */,
        dark: "rgba(40, 127, 135, 1)" /* #287F87 */,
        light: "rgba(133, 220, 228, 1)" /* #85DCE4 */,
        contrastText: "rgba(0, 0, 0, 1)" /* #000 */,
      },
      error: {
        main: "rgba(211, 47, 47, 1)" /* #d32f2f */,
        dark: "rgba(198, 40, 40, 1)" /* #C62828 */,
        light: "rgba(239, 83, 80, 1)" /* #EF5350 */,
        contrastText: "rgba(255, 255, 255, 1)" /* #fff */,
      },
      warning: {
        main: "rgba(255, 152, 0, 1)" /* #FF9800 */,
        dark: "rgba(245, 125, 0, 1)" /* #F57D00 */,
        light: "rgba(255, 205, 128, 1)" /* #FFCD80 */,
        contrastText: "rgba(0, 0, 0, 1)" /* #000 */,
      },
      info: {
        main: "rgba(2, 136, 209, 1)" /* #0288d1 */,
        dark: "rgba(1, 87, 155, 1)" /* #01579B */,
        light: "rgba(3, 169, 244, 1)" /* #03A9F4 */,
        contrastText: "rgba(255, 255, 255, 1)" /* #fff */,
      },
      success: {
        main: "rgba(46, 125, 50, 1)" /* #2e7d32 */,
        dark: "rgba(27, 94, 32, 1)" /* #1B5E20 */,
        light: "rgba(76, 175, 80, 1)" /* #4CAF50 */,
        contrastText: "rgba(255, 255, 255, 1)" /* #fff */,
      },
      action: {
        active: "rgba(0, 0, 0, 0.54)",
        hover: "rgba(0, 0, 0, 0.04)",
        selected: "rgba(0, 0, 0, 0.08)",
        disabled: "rgba(0, 0, 0, 0.26)",
        disabledBackground: "rgba(0, 0, 0, 0.12)",
        focus: "rgba(0, 0, 0, 0.12)",
      },
      divider: "rgba(0, 0, 0, 0.12)",
    },
    typography: {
      fontFamily: [
        "IBM Plex Sans",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      h1: {
        fontWeight: 700,
        fontSize: 60,
        lineHeight: 1.6,
      },
      h2: {
        fontWeight: 700,
        fontSize: 48,
        lineHeight: 1.22,
      },
      h3: {
        fontWeight: 700,
        fontSize: 34,
        lineHeight: 1.3,
      },
      h4: {
        fontWeight: 400,
        fontSize: 24,
        lineHeight: 1.4,
      },
      h5: {
        fontWeight: 700,
        fontSize: 20,
        lineHeight: 1.5,
      },
      h6: {
        fontWeight: 500,
        fontSize: 20,
        lineHeight: 1.5,
      },
      body1: {
        fontWeight: 400,
        fontSize: 16,
      },
      body2: {
        fontWeight: 400,
        fontSize: 14,
      },
      subtitle1: {
        fontWeight: 500,
        fontSize: 16,
      },
      subtitle2: {
        fontWeight: 500,
        fontSize: 14,
      },
      overline: {
        fontWeight: 400,
        fontSize: 12,
        textTransform: "inherit",
        lineHeight: 1.5,
      },
      caption: {
        fontWeight: 400,
        fontSize: 14,
      },
      button: {
        fontWeight: 500,
        fontSize: 14,
        textTransform: "inherit",
      },
      h1landing: {
        fontWeight: 700,
        fontSize: 96,
        lineHeight: 1.24,
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1landing: "h1",
          },
        },
      },
    },
  })
);

declare module "@mui/material/styles" {
  interface TypographyVariants {
    h1landing: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1landing?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h1landing: true;
  }
}

export default theme;

export type variantType =
  | "body1"
  | "body2"
  | "button"
  | "caption"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "inherit"
  | "overline"
  | "subtitle1"
  | "subtitle2";
