import { ReactElement, useEffect, useState } from "react";
import { useSetAtom, useAtomValue } from "jotai";
import { Box, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  PrimaryButton,
  ProfileIconAndMenu,
  HelpMenu,
} from "@web-applications/allinga-web-components";
import NavLinks from "shared/components/NavLinks";
import { fetchAndOpenMarkdownContent } from "../utils/markdown-renderer";
import { useOIDCLogin } from "../hooks/useOIDCLogin";
import ProfileSettings from "../components/ProfileSettings";
import LogoSpeaker from "../../assets/LogoSpeaker.png";
import { RELEASE_NOTES_URL } from "../constants/urls";
import {
  fetchUserPreferencesAtom,
  userPreferencesAtom,
  isConfigDialogOpenAtom,
} from "../../atoms";

function Navbar(): ReactElement {
  const oidcAuth = useOIDCLogin();
  const { isLoggedIn, token, auth } = oidcAuth;
  const { i18n, t } = useTranslation();
  const isLoggedInWithToken = isLoggedIn && !!token && token !== "";

  const handleLogin = async () => {
    await auth.signinRedirect();
  };

  const [showProfileSettings, setShowProfileSettings] = useState(false);
  const setConfigDialogOpen = useSetAtom(isConfigDialogOpenAtom);

  const fetchUserPreferences = useSetAtom(fetchUserPreferencesAtom);
  const userPreferences = useAtomValue(userPreferencesAtom);

  useEffect(() => {
    if (isLoggedInWithToken) {
      fetchUserPreferences();
    }
  }, [fetchUserPreferences, isLoggedInWithToken]);

  useEffect(() => {
    // default language to English if nothing found in userPreferences or if there's an error fetching userPreferences
    sessionStorage.setItem("language", userPreferences.language || "en");
    i18n.changeLanguage(userPreferences.language || "en").then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferences]);

  const handleLogout = () => {
    auth.signoutRedirect();
  };

  const handleProfileSettings = () => {
    setShowProfileSettings(true);
    setConfigDialogOpen(true);
  };

  return (
    <Grid
      id="navbar"
      container
      justifyContent="space-between"
      alignItems="center"
      padding="20px 24px"
      sx={{
        position: "sticky",
        zIndex: 3,
        top: 0,
        backgroundColor: auth.isAuthenticated ? "#FFFFFF" : "#ebf7f7",
      }}
    >
      <Grid item>
        <Stack direction="row" gap={5} alignItems="center">
          <Box>
            <a href="/" rel="noopener noreferrer">
              <Box component="img" src={LogoSpeaker} alt="Speaker" />
            </a>
          </Box>
          {isLoggedInWithToken ? (
            // Show page links for logged-in user
            <NavLinks />
          ) : (
            " "
          )}
        </Stack>
      </Grid>
      <Grid item>
        {auth.isAuthenticated ? (
          // Show menus for logged-in user
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            sx={{ marginRight: "8px" }}
          >
            <HelpMenu
              additionalHelpMenuOptions={[
                {
                  name: t("helpMenu.releaseNotes"),
                  url: "#",
                  id: "release-notes-menu-item",
                  onClick: () => fetchAndOpenMarkdownContent(RELEASE_NOTES_URL),
                },
              ]}
              shouldOpenInNewTab={false}
              IMPRINT_URL="/imprint"
              PRIVACY_URL="/privacy"
              privacyText={t("helpMenu.privacy")}
              imprintText={t("helpMenu.imprint")}
              reportIssuesText={t("helpMenu.reportBug")}
            />
            <ProfileIconAndMenu
              userName={userPreferences?.username || ""}
              avatarIcon={userPreferences?.avatar || ""}
              profileSettingsText={t("profileSettings.title")}
              logOutText={t("profileMenu.logOut")}
              handleLogOutClick={handleLogout}
              handleProfileSettingsClick={handleProfileSettings}
            />
          </Stack>
        ) : (
          // Render login button for users who are not logged in
          <PrimaryButton
            text={t("buttons.logIn")}
            onSubmit={handleLogin}
            id="navbar-login-button"
          />
        )}
      </Grid>
      {showProfileSettings && <ProfileSettings />}
    </Grid>
  );
}

export default Navbar;
