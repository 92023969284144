import { ThemeProvider } from "@mui/material/styles";
import "../../i18n/config";
import { BrowserRouter as Router } from "react-router-dom";
import theme from "../../styles/theme";
import AppRoutes from "./app.routes";

function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <main>
        <Router basename={process.env.REACT_APP_BASE_HREF}>
          <AppRoutes />
        </Router>
      </main>
    </ThemeProvider>
  );
}

export default App;
