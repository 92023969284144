import React from "react";

export type WindowConstraintsResult = [number, number];

/**
 * Calculates the maximum size a configuration dialog can be safely resized to without
 * being bigger than the view port itself. The values are updated when the user resizes
 * dialog editor and on load
 *
 * @param padding padding to apply to limit the configuration dialog from being
 * bigger than the viewport
 * @returns maximum width and height the configuration dialog can be based on the viewport
 */
export function useWindowConstraints(padding = 100): WindowConstraintsResult {
  const [maxConstraints, setMaxConstraints] = React.useState<[number, number]>([
    500, 500,
  ]);

  React.useEffect(() => {
    function handler() {
      setMaxConstraints([
        Math.round(window.innerWidth - padding),
        Math.round(window.innerHeight - padding),
      ]);
    }

    window.addEventListener("resize", handler);
    handler();
    return () => window.removeEventListener("resize", handler);

    // Add the resize listener only on mount and remove it on dismount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return maxConstraints;
}
