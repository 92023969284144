import { Stack } from "@mui/material";
import React, { ReactElement, useState } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";
import {
  fetchUserPreferencesAtom,
  isConfigDialogOpenAtom,
  updateUserPreferencesAtom,
  userPreferencesAtom,
} from "../../../atoms";
import ConfigurationTemplate from "../../containers/ConfigurationTemplate";
import ConfigurationTemplateActions from "../ConfigurationTemplateActions";
import DialogResizableBox from "../../containers/DialogResizableBox";
import AvatarSelectionSettings from "./AvatarSelectionSettings";
import LanguageSelectorSettings from "./LanguageSelectionSettings";

function ProfileSettings(): ReactElement {
  const userPreferences = useAtomValue(userPreferencesAtom);
  const updateUserPreferences = useSetAtom(updateUserPreferencesAtom);
  const fetchUserPreferences = useSetAtom(fetchUserPreferencesAtom);
  const setConfigDialogOpen = useSetAtom(isConfigDialogOpenAtom);

  const [selectedLanguage, setSelectedLanguage] = useState(
    userPreferences.language
  );

  const [selectedAvatar, setSelectedAvatar] = useState(
    userPreferences.avatar ? userPreferences.avatar : ""
  );
  const { i18n, t } = useTranslation();

  const handleClose = () => {
    setConfigDialogOpen(false);
  };
  const handleCancelClick = () => {
    handleClose();
    setSelectedLanguage(userPreferences.language);
  };

  const handleSaveClick = () => {
    handleClose();
    sessionStorage.setItem("language", selectedLanguage);
    updateUserPreferences({
      userAvatar: selectedAvatar,
      userLanguage: selectedLanguage,
    }).then();
    fetchUserPreferences().then();
    setTimeout(() => {
      i18n.changeLanguage(selectedLanguage).then();
    }, 1);
  };

  return (
    <DialogResizableBox>
      <ConfigurationTemplate
        formActions={
          <ConfigurationTemplateActions
            onCancel={handleCancelClick}
            onSave={handleSaveClick}
          />
        }
        title={t("profileSettings.title")}
        handleCloseClick={handleClose}
      >
        <Stack gap={3} direction="column">
          <AvatarSelectionSettings
            selectedAvatar={selectedAvatar}
            setSelectedAvatar={setSelectedAvatar}
          />
          <LanguageSelectorSettings
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
          />
        </Stack>
      </ConfigurationTemplate>
    </DialogResizableBox>
  );
}

export default ProfileSettings;
