import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../App.css";

function NavLinks() {
  const { t } = useTranslation();
  return (
    <nav id="nav-links">
      <NavLink to="/">{t("navLinks.dashboard")}</NavLink>

      <NavLink to="/groups">{t("navLinks.groups")}</NavLink>

      <NavLink to="/users">{t("navLinks.users")}</NavLink>
    </nav>
  );
}

export default NavLinks;
