import React, { ReactElement } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { Box, CircularProgress } from "@mui/material";
import HomePage from "../HomePage";
import Dashboard from "../Dashboard";
import Groups from "../Groups";
import Users from "../Users";
import { PATHS } from "../../shared/constants/paths";
import Privacy from "../../shared/containers/Privacy";
import Imprint from "../../shared/containers/Imprint";
import Navbar from "../../shared/containers/Navbar";

/**
 * Routing of Allinga Admin root
 */

function AppRoutes(): ReactElement {
  const auth = useAuth();

  // wait for the authentication to be loaded, true or false
  if (auth.isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // auth is not loading anymore, so we can check if the user is authenticated
  return (
    <>
      {!auth.isLoading && <Navbar />}
      <Routes>
        <Route
          path={PATHS.root}
          element={auth.isAuthenticated ? <Dashboard /> : <HomePage />}
        />
        <Route
          path={PATHS.groups}
          element={
            auth.isAuthenticated ? <Groups /> : <Navigate to={PATHS.root} />
          }
        />
        <Route
          path={PATHS.users}
          element={
            auth.isAuthenticated ? <Users /> : <Navigate to={PATHS.root} />
          }
        />
        <Route path={PATHS.privacy} element={<Privacy />} />
        <Route path={PATHS.imprint} element={<Imprint />} />

        {/* Redirect any URL to root */}
        <Route path="*" element={<Navigate to={PATHS.root} />} />
      </Routes>
    </>
  );
}

export default AppRoutes;
