/* eslint-disable import/no-extraneous-dependencies */
import MarkdownIt from "markdown-it";
import hljs from "highlight.js";

function fetchAndOpenMarkdownContent(filePath: string): void {
  const md = new MarkdownIt({
    highlight: (str, lang) => {
      if (lang && hljs.getLanguage(lang)) {
        const highlighted = hljs.highlight(str, { language: lang });
        if (highlighted && highlighted.value) {
          return highlighted.value;
        }
      }
      return "";
    },
  });

  fetch(filePath)
    .then((response) => response.text())
    .then((text) => {
      const htmlContent = md.render(text);

      const newTab = window.open("", "_blank");
      if (newTab) {
        newTab.document.write(`
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.4.0/styles/github.min.css">
          <style>
            #markdown-content {
              padding: 0 50px;
              font-family: 'IBM Plex Sans', sans-serif; 
            }
            .box-container {
              border: 1px solid #ddd;
              padding: 10px;
              margin-top: 20px;
            }
            table {
              border-collapse: collapse;
              width: 100%;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
          </style>
          <div id="markdown-content">${htmlContent}</div>
        `);

        // Find all <code> elements containing XML content and enclose them within a box container
        const xmlElements =
          newTab.document.querySelectorAll("code.language-xml");
        xmlElements.forEach((xmlElement) => {
          if (xmlElement.parentNode) {
            const boxContainer = newTab.document.createElement("div");
            boxContainer.classList.add("box-container");
            boxContainer.innerHTML = xmlElement.outerHTML;
            xmlElement.parentNode.replaceChild(boxContainer, xmlElement);
          }
        });

        newTab.document.close();
      }
    });
}

export { fetchAndOpenMarkdownContent };
