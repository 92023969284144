import { OIDC_AUTHORITY, OIDC_CLIENT_ID } from "shared/constants/oidc";

export const oidcConfig = {
  authority: OIDC_AUTHORITY,
  client_id: OIDC_CLIENT_ID,
  redirect_uri: `${window.location.origin}${process.env.PUBLIC_URL}`,
  response_type: "code",
  scope: "openid profile email",
  post_logout_redirect_uri: `${window.location.origin}${process.env.PUBLIC_URL}`,
};
