import { Stack, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";

/**
 * React component to display the legal summary
 */
function Imprint() {
  const { t } = useTranslation();

  return (
    <Stack
      className="container-wrapper outer-container background-color"
      direction="row"
    >
      <Box className="inner-container">
        <Stack direction="column" spacing={3}>
          <Stack spacing={1}>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              {t("legalTerms.imprint.heading")}
            </Typography>
            <Typography>{t("legalTerms.imprint.text1")}</Typography>
            <br />
            <Typography>
              IAIS
              <br />
              Schloss Birlinghoven 1
              <br />
              53757 Sankt Augustin
              <br />
              <br />
              IIS
              <br />
              Am Wolfsmantel 33
              <br />
              91058 Erlangen
              <br />
            </Typography>
            <br />
            <Typography>{t("legalTerms.imprint.text2")}</Typography>
            <Typography>
              Fraunhofer-Gesellschaft zur Förderung der angewandten Forschung
              e.V.
              <br />
              Hansastraße 27c, 80686 {t("legalTerms.imprint.munich")}
              <br />
              {t("legalTerms.imprint.phone")} +49 89 1205- 0
              <br />
              Fax +49 89 1205-7531
              <br />
              E-Mail: info(at)zv.fraunhofer.de
              <br />
              <br />
              <Link href="https://www.fraunhofer.de">www.fraunhofer.de</Link>
            </Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {t("legalTerms.imprint.vat")}
            </Typography>
            <Typography>DE 129515865</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {t("legalTerms.imprint.jurisdiction.heading")}
            </Typography>
            <Typography>
              {t("legalTerms.imprint.jurisdiction.line1")}
              <br />
              {t("legalTerms.imprint.jurisdiction.line2")}
              <br />
              {t("legalTerms.imprint.jurisdiction.line3")}
            </Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {t("legalTerms.imprint.editor.heading")}
            </Typography>
            <Typography>
              Mandy Garcia
              <br />
              phone +49 9131 776-6178
              <br />
              <Link href="https://www.iis.fraunhofer.de/en/send-mail?m=1.5ec82e745e11da005455e021b264a5fc&k=7765626d6173746572&r=2f656e2f696d7072696e742e68746d6c">
                webmaster@iis.fraunhofer.de
              </Link>
            </Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {t("legalTerms.imprint.executiveBoard.heading")}
            </Typography>
            <Typography>
              <Box component="span" fontWeight="bold">
                Prof. Dr.-Ing. Holger Hanselka
              </Box>{" "}
              | {t("legalTerms.imprint.executiveBoard.president")}
              <br />
              <Box component="span" fontWeight="bold">
                Prof. Dr. Axel Müller-Groeling
              </Box>{" "}
              | {t("legalTerms.imprint.executiveBoard.member")}
              <br />
              <Box component="span" fontWeight="bold">
                Ass. jur. Elisabeth Ewen
              </Box>{" "}
              | {t("legalTerms.imprint.executiveBoard.member")}
              <br />
              <Box component="span" fontWeight="bold">
                Dr. Sandra Krey
              </Box>{" "}
              | {t("legalTerms.imprint.executiveBoard.member")}
            </Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {t("legalTerms.imprint.usageRights.heading")}
            </Typography>
            <Typography>
              Copyright © by
              <br />
              Fraunhofer-Gesellschaft
              <br />
              {t("legalTerms.imprint.usageRights.address")}
            </Typography>
            <Typography>
              {t("legalTerms.imprint.usageRights.text1")}
              <br />
              {t("legalTerms.imprint.usageRights.text2")}
            </Typography>
            <Typography>{t("legalTerms.imprint.usageRights.text3")}</Typography>
            <Typography>
              {t("legalTerms.imprint.usageRights.text4")}
              <br />
              Institut für Intelligente Analyse- und Informationssysteme und
              Institut für Integrierte Schaltungen
            </Typography>
            <br />
            <Typography>
              IAIS
              <br />
              Schloss Birlinghoven 1
              <br />
              53757 Sankt Augustin
            </Typography>
            <Typography>
              IIS
              <br />
              Am Wolfsmantel 33
              <br />
              91058 Erlangen
            </Typography>
            <br />
            <Typography>{t("legalTerms.imprint.usageRights.text5")}</Typography>
            <Typography>{t("legalTerms.imprint.usageRights.text6")}</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {t("legalTerms.imprint.disclaimer.heading")}
            </Typography>
            <Typography>{t("legalTerms.imprint.disclaimer.text1")}</Typography>
            <Typography>{t("legalTerms.imprint.disclaimer.text2")}</Typography>
            <Typography>{t("legalTerms.imprint.disclaimer.text3")}</Typography>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
}

export default Imprint;
