import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import deTranslations from "./locales/de/translation.json";
import enTranslations from "./locales/en/translation.json";

export const defaultNS = "translation";

export const resources = {
  en: { translation: enTranslations },
  de: { translation: deTranslations },
} as const;

const defaultLanguage = sessionStorage.getItem("language") ?? "en";

i18n
  .use(initReactI18next)
  // init i18next with default options https://www.i18next.com/overview/configuration-options
  .init({
    lng: defaultLanguage,
    debug: false, // debug only in development
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    defaultNS,
    resources,
  });

export default i18n;
