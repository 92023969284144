import React, { ReactElement } from "react";
import { AvatarSelection } from "@web-applications/allinga-web-components";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";

interface AvatarSelectionSettingsProps {
  selectedAvatar: string;
  setSelectedAvatar: React.Dispatch<React.SetStateAction<string>>;
}

function AvatarSelectionSettings({
  selectedAvatar,
  setSelectedAvatar,
}: AvatarSelectionSettingsProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Stack gap={1}>
      <AvatarSelection
        currentAvatar={selectedAvatar}
        setCurrentAvatar={setSelectedAvatar}
        userAvatarHeader={t("profileSettings.chooseAvatar")}
      />
    </Stack>
  );
}

export default AvatarSelectionSettings;
