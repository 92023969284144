import { Typography, Stack, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import theme from "../../styles/theme";

function Footer(): JSX.Element {
  const year: number = new Date().getFullYear();
  const { t } = useTranslation();

  return (
    <Stack
      spacing={4}
      direction="row"
      alignItems="center"
      justifyContent="end"
      padding="40px 24px 24px"
    >
      <Typography variant="body1">©{year}</Typography>
      <Typography variant="body1">
        <Link
          id="imprint-link"
          href="/imprint"
          component="a"
          style={{
            textDecoration: "underline",
            color: theme.palette.primary.main,
          }}
        >
          {t("legalTerms.imprint.button")}
        </Link>
      </Typography>

      <Typography variant="body1">
        <Link
          id="privacy-link"
          href="/privacy"
          component="a"
          style={{
            textDecoration: "underline",
            color: theme.palette.primary.main,
          }}
        >
          {t("legalTerms.privacy.button")}
        </Link>
      </Typography>
    </Stack>
  );
}

export default Footer;
