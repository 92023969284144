import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";
import { DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export interface ConfigurationTemplateProps {
  title: React.ReactNode;
  formActions: React.ReactNode;
  children: React.ReactNode;
  handleCloseClick?: () => void;
}

function ConfigurationTemplate({
  title,
  formActions,
  children,
  handleCloseClick,
}: ConfigurationTemplateProps): JSX.Element {
  return (
    <>
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">{title}</Typography>
          <IconButton onClick={handleCloseClick} id="close-button-config">
            <CloseOutlinedIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ flexGrow: 1, padding: "16px" }}
        id="draggable-dialog-content"
      >
        {children}
      </DialogContent>
      <DialogActions sx={{ margin: 1 }} id="draggable-dialog-actions">
        {formActions}
      </DialogActions>
    </>
  );
}

export default ConfigurationTemplate;
