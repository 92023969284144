import { atom } from "jotai";
import { UserPreferences, UserPreferencesPayload } from "../shared/models";
import {
  fetchUserPreferences,
  updateUserPreferences,
} from "../shared/requests";
import { authTokenAtom } from "./auth";

export const userPreferencesAtom = atom<UserPreferences>({
  language: "",
  avatar: "",
  username: "",
});

export const fetchUserPreferencesAtom = atom(null, async (get, set) => {
  // Get the token from the auth token atom
  const token = get(authTokenAtom);

  try {
    // Get the preferences from the backend
    const response: UserPreferences = await fetchUserPreferences(token);
    set(userPreferencesAtom, response);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
});

export const updateUserPreferencesAtom = atom(
  null,
  async (get, set, { userAvatar, userLanguage }: UserPreferencesPayload) => {
    // Get the token from the auth token atom
    const token = get(authTokenAtom);
    const userPreferences = get(userPreferencesAtom);

    try {
      // Get the preferences from the backend
      await updateUserPreferences(
        userAvatar,
        userLanguage,
        userPreferences.username || "",
        token
      );
      set(userPreferencesAtom, {
        ...userPreferences,
        language: userLanguage,
        avatar: userAvatar,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }
);
