import { useAtom } from "jotai";
import { useEffect } from "react";
import { AuthContextProps, useAuth } from "react-oidc-context";
import { authTokenAtom } from "../../atoms";

interface IdTokenClaims {
  hasRealmRole(role: string): boolean;
}

interface OIDCLoginInformation {
  isLoggedIn: boolean;
  hasAllingaAdminAccess: boolean;
  token: string;
  userProfile?: IdTokenClaims;
  auth: AuthContextProps;
}

export const useOIDCLogin = (): OIDCLoginInformation => {
  const auth = useAuth();
  const [authToken, setAuthToken] = useAtom(authTokenAtom);
  let isLoggedIn = auth.isAuthenticated;

  useEffect(() => {
    if (auth.isAuthenticated) {
      setAuthToken(auth.user?.access_token || "");
    }
  }, [
    isLoggedIn,
    auth.isAuthenticated,
    auth.user?.access_token,
    auth.user?.profile,
    setAuthToken,
  ]);

  isLoggedIn &&= authToken !== "";

  const userProfile = auth.user?.profile as IdTokenClaims | undefined;

  const hasAllingaAdminAccess =
    userProfile && typeof userProfile.hasRealmRole === "function"
      ? userProfile.hasRealmRole("AllingaAdministrator")
      : false;

  return {
    isLoggedIn,
    hasAllingaAdminAccess,
    token: authToken,
    userProfile,
    auth,
  };
};
